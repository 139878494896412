::-webkit-scrollbar {
	width: 8px;
	background-color: #ffffff;
	height: 13px;
}
::-webkit-scrollbar-thumb {
	background-color: #d2d2d2;
	border: 5px solid #ffffff;

	border-radius: 4px;
}

@media (max-width: 575.98px) {
	.sb-nav-fixed #layoutSidenav #layoutSidenav_content {
		top: 48px !important;
	}
}

.fixed-top,
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav,
.sb-nav-fixed .sb-topnav {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

#layoutAuthentication {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
#layoutAuthentication #layoutAuthentication_content {
	min-width: 0;
	flex-grow: 1;
}
#layoutAuthentication #layoutAuthentication_footer {
	min-width: 0;
}

#layoutSidenav {
	display: flex;
}
#layoutSidenav #layoutSidenav_nav {
	flex-basis: 225px;
	flex-shrink: 0;
	transition: transform 0.15s ease-in-out;
	z-index: 1038;
	transform: translateX(-225px);
}
#layoutSidenav #layoutSidenav_content {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 0;
	flex-grow: 1;
	min-height: calc(100vh - 56px);
	margin-left: -225px;
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
	transform: translateX(0);
}
.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	z-index: 1037;
	opacity: 0.5;
	transition: opacity 0.3s ease-in-out;
}

@media (min-width: 992px) {
	#layoutSidenav #layoutSidenav_nav {
		transform: translateX(0);
	}
	#layoutSidenav #layoutSidenav_content {
		margin-left: 0;
		transition: margin 0.15s ease-in-out;
	}

	.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
		transform: translateX(-225px);
	}
	.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content {
		margin-left: -225px;
	}
	.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
		display: none;
	}
}
.sb-nav-fixed .sb-topnav {
	z-index: 1039;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
	width: 225px;
	height: 100vh;
	z-index: 1038;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
	padding-top: 56px;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
	overflow-y: auto;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_content {
	padding-left: 225px;
	top: 56px;
}

.nav .nav-link .sb-nav-link-icon,
.sb-sidenav-menu .nav-link .sb-nav-link-icon {
	margin-right: 0.3rem;
}

.sb-topnav {
	padding-left: 0;
	height: 56px;
	z-index: 1039;
	background-color: white !important;
}
.sb-topnav .navbar-brand {
	width: 205px;
	margin: 0;
}
.sb-topnav.navbar-dark #sidebarToggle {
	color: rgba(255, 255, 255, 0.5);
}
.sb-topnav.navbar-light #sidebarToggle {
	color: #212529;
}
@media (max-width: 576px) {
	.sb-topnav {
		height: 48px;
	}
}
.sb-sidenav {
	display: flex;
	flex-direction: column;
	height: 100%;
	flex-wrap: nowrap;
}
.sb-sidenav .sb-sidenav-menu {
	flex-grow: 1;
}
.sb-sidenav .sb-sidenav-menu .nav {
	flex-direction: column;
	flex-wrap: nowrap;
}
.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-heading {
	padding: 1.75rem 1rem 0.75rem;
	font-size: 0.75rem;
	font-weight: bold;
	text-transform: uppercase;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link {
	display: flex;
	align-items: center;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	position: relative;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-nav-link-icon {
	font-size: 0.9rem;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-sidenav-collapse-arrow {
	display: inline-block;
	margin-left: auto;
	transition: transform 0.15s ease;
}
.sb-sidenav
	.sb-sidenav-menu
	.nav
	.nav-link.collapsed
	.sb-sidenav-collapse-arrow {
	transform: rotate(-90deg);
}
.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested {
	margin-left: 1.5rem;
	flex-direction: column;
}
.sb-sidenav .sb-sidenav-footer {
	padding: 0.75rem;
	flex-shrink: 0;
}

.sb-sidenav-dark {
	background-color: #212529;
	color: rgba(255, 255, 255, 0.5);
}
.sb-sidenav-dark .sb-sidenav-menu .sb-sidenav-menu-heading {
	color: rgba(255, 255, 255, 0.25);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link {
	color: rgba(255, 255, 255, 0.5);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-nav-link-icon {
	color: rgba(255, 255, 255, 0.25);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
	color: rgba(255, 255, 255, 0.25);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover {
	color: #fff;
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link.active {
	color: #fff;
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
	color: #fff;
}
.sb-sidenav-dark .sb-sidenav-footer {
	background-color: #343a40;
}

.sb-sidenav-light {
	background-color: white;
	color: #212529;
}
.sb-sidenav-light .sb-sidenav-menu .sb-sidenav-menu-heading {
	color: #e9ecef;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link {
	color: #e9ecef;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-nav-link-icon {
	color: #e9ecef;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
	color: #e9ecef;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link:hover,
.sb-sidenav-light .sb-sidenav-menu .nav-link.active,
.sb-sidenav-light .sb-sidenav-menu .nav-link:hover .sb-sidenav-collapse-arrow,
.sb-sidenav-light .sb-sidenav-menu .nav-link.active .sb-nav-link-icon,
.sb-sidenav-light .sb-sidenav-menu .nav-link:hover .sb-nav-link-icon {
	color: #e9ecef;
	font-weight: 600;
}

.sb-sidenav-light .sb-sidenav-footer {
	background-color: #e9ecef;
}

.sun-editor .se-resizing-bar {
	display: none;
}

.nav-link-hover {
	background-color: #147efb !important;
	margin-bottom: 1px !important;
}

.nav-link-hover:hover {
	background-color: #005cbf !important;
}

.nav-link-on-hover {
	background-color: #005cbf !important;
}
