.image-default {
	width: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.display-flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Radius */
.radius-8 {
	border-radius: 8px !important;
}

.normal-border-16 {
	border-radius: 16px !important;
}
/* Radius */

/* Text Overflow */
.text-overflow-1-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.text-overflow-2-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.text-overflow-3-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.text-overflow-4-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}
/* Text Overflow */

/* Boostrap Pagination */
.page-item .page-link {
	color: var(--primary-color);
}

.page-item .page-link:hover {
	color: var(--primary-color);
}

.page-item.active .page-link {
	background: var(--primary-color);
	border: 1px solid var(--primary-color);
}

.page-item.active .page-link:hover {
	color: white;
}
/* Boostrap Pagination */

.sun-editor {
	border: none !important;
}

.se-wrapper-inner {
	padding-right: 0 !important;
	padding-left: 0 !important;
}
