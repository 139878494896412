:root {
	--nav-height: 4rem;
	--primary-color: #3f40fd;
	--mute-color: #f5f5f5;
}

.container-wrapper {
	margin: 0 auto !important;
	width: 1170px !important;
	max-width: 100% !important;
}

.text-color {
	color: var(--primary-color) !important;
}

.nav-height {
	height: var(--nav-height);
	background-color: white;
}

.navbar-text {
	color: black !important;
	font-size: 1.2rem !important;
	font-weight: bold !important;
	text-decoration: none;
}

.navbar-text:hover {
	color: var(--primary-color) !important;
}

.sub-navbar-text {
	color: black !important;
	font-size: 1rem !important;
	text-decoration: none;
}

.sub-navbar-text:hover {
	color: var(--primary-color) !important;
}

.search-button {
	background-color: var(--primary-color) !important;
	border: none;
}

.circle {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	color: var(--primary-color) !important;
}

.footer-text {
	text-decoration: none;
	color: black;
}

.footer-text:hover {
	color: var(--primary-color) !important;
}

.text-overflow {
	/* display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical; */
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.title-layout {
	background-color: var(--primary-color);
	height: 7rem;
	line-height: 7rem;
	width: 100%;
	color: white;
	text-align: center;
	vertical-align: middle;
	font-size: 2rem;
	font-weight: bold;
	margin: 1.5rem 0rem;
}

.text-link {
	color: black !important;
	text-decoration: none;
}

.text-link-hover {
	color: var(--primary-color) !important;
	text-decoration: none;
}

.text-link:hover {
	color: var(--primary-color) !important;
}

.primary-button {
	color: white !important;
	background-color: var(--primary-color) !important;
	border: none;
}

.primary-button:hover {
	color: var(--primary-color) !important;
	background-color: white !important;
	border: 1px solid var(--primary-color) !important;
}

.mute-button {
	color: black !important;
	background-color: var(--mute-color) !important;
	border: 1px solid #d2d2d2 !important;
}

.mute-button:hover {
	color: white !important;
	background-color: var(--primary-color) !important;
}

.dropdown-toggle::after {
	display: none !important;
}

.dropdown-menu {
	border-radius: 0 !important;
}

.header-show {
	display: block;
}

.nav-menu {
	background-color: white;
	width: 15rem;
	height: 100vh;
	justify-content: flex-start;
	position: fixed;
	top: 4rem;
	left: -100%;
	transition: 850ms;
	z-index: 1050;
}

.nav-menu.active {
	left: 0;
	transition: 350ms;
}

.nav-menu::after {
	position: absolute;
	width: 100vw;
	height: 100vh;
	opacity: 0.5;
	background-color: black;
	transition: 850ms;
	z-index: 1041;
}

.curtain {
	display: none;
}

.sidebar-text {
	font-weight: bold !important;
	color: black !important;
	font-size: 1.1rem !important;
	text-decoration: none;
	cursor: pointer;
}

.sidebar-text:hover {
	color: var(--primary-color) !important;
}

.sidebar-section {
	display: none;
}

.toggle-button {
	font-size: 1.5rem;
	cursor: pointer;
}

@media (max-width: 992px) {
	.sidebar-section {
		display: block;
	}

	.header-show {
		display: none;
	}

	.curtain {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		opacity: 0;
		background-color: black;
		transition: 850ms;
		z-index: 1040;
		display: none;
	}

	.curtain.active {
		opacity: 0.5;
		transition: 350ms;
		display: block;
	}
}
